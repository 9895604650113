import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { components, styles, InternalContactTypeEnum } from 'herald-fe-shared';

import { SnippetContext } from 'components/context/SnippetWrapper';
import { ModalContext, ModalFooter } from 'components/context/ModalWrapper';
import { environment, setShortcut } from 'lib';

const {
  ContactName,
  ContactEmail,
  ContactPhone,
  ContactProspectToggle,
} = components.forms;

const StyledModal = styled.div`
  .header {
    padding: 2rem;
    border-bottom: ${styles.BORDER};
  }
  .content {
    padding: 0 2rem;
  }
  .form-group {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .input__container {
    height: 3rem;
  }
  input {
    font-size: 1.2rem;
  }
`;

const ContactEditModal: React.FC = () => {
  const { contact, setContact } = useContext(SnippetContext);
  const { close } = useContext(ModalContext);

  const [name, setName] = useState(contact?.name || '');
  const [email, setEmail] = useState(contact?.email || '');
  const [phone, setPhone] = useState(contact?.phone || '');
  const [prospect, setProspect] = useState(
    contact?.type === InternalContactTypeEnum.Prospect || false
  );

  const nameRef = useRef(null);

  const onSave = useCallback(() => {
    setContact({
      ...contact,
      isNew: true,
      name,
      email,
      phone,
      type: prospect
        ? InternalContactTypeEnum.Prospect
        : InternalContactTypeEnum.Customer,
    });
    close();
  }, [close, contact, setContact, name, email, phone, prospect]);

  useEffect(() => {
    setShortcut('SAVE', onSave);
  }, [onSave]);

  useEffect(() => {
    if (nameRef && nameRef.current) {
      setTimeout(() => (nameRef.current as any).focus(), 250);
    }
  }, [nameRef]);

  return (
    <StyledModal>
      <div className="header">
        <h4>Edit Contact</h4>
      </div>

      <ContactName
        name={name}
        setName={setName}
        horizontal={!environment.front}
        inputProps={{ ref: nameRef }}
      />
      <ContactEmail
        email={email}
        setEmail={setEmail}
        horizontal={!environment.front}
      />
      <ContactPhone
        phone={phone}
        setPhone={setPhone}
        horizontal={!environment.front}
      />
      <ContactProspectToggle
        prospect={prospect}
        setProspect={setProspect}
        horizontal={!environment.front}
      />

      <ModalFooter>
        <components.ButtonGroup right={true}>
          <components.Button secondary={true} onClick={close}>
            Cancel
          </components.Button>
          <components.Tooltip
            message={
              <>
                <code>{environment.mac ? '⌘' : 'Ctrl'}</code>
                <code>Enter</code>
              </>
            }
          >
            <components.Button onClick={onSave}>OK</components.Button>
          </components.Tooltip>
        </components.ButtonGroup>
      </ModalFooter>
    </StyledModal>
  );
};

export default ContactEditModal;
