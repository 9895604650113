import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { icons, styles, colors } from 'herald-fe-shared';

import { hooks } from 'lib';

const Styled = styled.div`
  .walkthrough__message {
    display: flex;
    padding: 1rem;
    align-items: center;
    z-index: 11;
    position: relative;
    transition: 250ms all;
  }
  .walkthrough__message__icon {
    margin-right: 1rem;
  }
  .walkthrough__message__icon svg {
    fill: ${colors.PURPLE_LIGHT()};
  }
  .walkthrough__message p,
  .walkthrough__message button {
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 0;
  }
  .walkthrough__fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: 250ms all;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
  }
  .walkthrough__fade--active {
    opacity: 1;
    visibility: visible;
  }
  .walkthrough__fade__portion {
    content: '';
    display: block;
    width: 100vw;
    background: ${colors.WHITE(0.95)};
    position: absolute;
    left: 0;
  }
  .walkthrough__fade__portion--top {
    height: 100px;
  }
  .walkthrough__fade__portion--bottom {
    height: 100vh;
  }
  .walkthrough__fade__text {
    position: fixed;
    z-index: 12;
    width: 100%;
    padding: 1rem;
    background: ${colors.PURPLE_LIGHT(0.1)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .walkthrough__fade__text__icon {
    border-radius: 50%;
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    background: ${colors.WHITE()};
    margin-right: 1rem;
    box-shadow: ${styles.BOX_SHADOW};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .walkthrough__fade__text__icon svg {
    fill: ${colors.GRAY_3()};
  }
  .walkthrough__fade__text__description {
    color: ${colors.GRAY_4()};
  }
`;

interface ISection {
  class: string;
  icon: JSX.Element;
  description: JSX.Element;
}

interface ISectionPosition {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

const SECTIONS: { [key: string]: ISection } = {
  contact: {
    class: '.form-group--contact',
    icon: <icons.UserFriends />,
    description: (
      <>
        <strong>Contact.</strong> The customer providing feedback.
      </>
    ),
  },
  issue: {
    class: '.form-group--issue',
    icon: <icons.DraftingCompass />,
    description: (
      <>
        <strong>Topic.</strong> Find an existing topic or create a new topic
        related to the customer feedback.
      </>
    ),
  },
  quote: {
    class: '.form-group--quote',
    icon: <icons.Feather />,
    description: (
      <>
        <strong>Quote.</strong> The actual words of the customer.
      </>
    ),
  },
};

const getPositions = (): { [key: string]: ISectionPosition } => {
  const positions = {};
  Object.keys(SECTIONS).forEach((c) => {
    const rect = document
      .querySelector(SECTIONS[c].class)
      ?.getBoundingClientRect();
    if (rect) {
      positions[c] = {
        x1: rect.x,
        x2: rect.x + rect.width,
        y1: rect.y,
        y2: rect.y + rect.height,
      };
    } else {
      positions[c] = null;
    }
  });
  return positions;
};

const AddQuoteWalkthrough: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<ISection | null>(null);
  const [position, setPosition] = useState<ISectionPosition | null>(null);

  const { data: me } = hooks.useMe();

  const openSection = useCallback((section: string) => {
    const p = getPositions();
    if (p) {
      setCurrentSection(SECTIONS[section]);
      setPosition(p[section]);
    }
  }, []);

  const sectionButton = useCallback(
    (section: string, text?: string) => (
      <button
        onMouseEnter={() => openSection(section)}
        onMouseLeave={() => setCurrentSection(null)}
      >
        {text || section}
      </button>
    ),
    [openSection]
  );

  if (!me || !me.rookie) {
    return null;
  }

  return (
    <Styled>
      <div
        className="walkthrough__message"
        style={
          currentSection ? undefined : { background: colors.PURPLE_LIGHT(0.1) }
        }
      >
        <div className="walkthrough__message__icon">
          <icons.QuestionCircle />
        </div>
        <p>
          Herald saves feedback by connecting 3 pieces of information: the{' '}
          {sectionButton('contact')}, their actual {sectionButton('quote')}, and
          the {sectionButton('issue', 'topic')} their feedback is related to.
        </p>
      </div>
      <div
        className={`walkthrough__fade walkthrough__fade--${
          currentSection ? 'active' : 'inactive'
        }`}
      >
        <div
          className="walkthrough__fade__portion walkthrough__fade__portion--top"
          style={{ height: (position?.y1 || 0) + 5 }}
        />
        <div
          className="walkthrough__fade__portion walkthrough__fade__portion--bottom"
          style={{ top: (position?.y2 || 0) + 10 }}
        />
        <div
          className="walkthrough__fade__text"
          style={
            (position?.y2 || 0) > window.innerHeight * 0.7
              ? { bottom: window.innerHeight - (position?.y1 || 0) - 5 }
              : { top: (position?.y2 || 0) + 10 }
          }
        >
          <div className="walkthrough__fade__text__icon">
            {currentSection?.icon}
          </div>
          <div className="walkthrough__fade__text__description">
            {currentSection?.description}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default AddQuoteWalkthrough;
