import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { icons, components, colors, styles } from 'herald-fe-shared';

import { environment } from 'lib';

interface IModalContent {
  content?: React.FC;
  footer?: JSX.Element;
  props?: any;
}

export interface IModalContext {
  close: () => void;
  open: (content: IModalContent) => void;
}

export const ModalFooter: React.FC = (props) => (
  <div className="modal__footer">{props.children}</div>
);

export const ModalContext = React.createContext<IModalContext>({} as any);

const Styled = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 40;
  position: fixed;
  visibility: hidden;
  transition: 500ms all;
  .cross {
    position: absolute;
    left: 1rem;
    top: 1rem;
    width: 1rem;
    fill: ${colors.PURPLE_LIGHT(0.5)};
    z-index: 31;
    cursor: pointer;
  }
  .modal__content {
    width: calc(100vw - 3rem);
    height: 100%;
    position: absolute;
    top: 0;
    left: 3rem;
    background: ${colors.GRAY_1()};
    z-index: 31;
    border-radius: ${styles.BORDER_RADIUS};
    transition-delay: 250ms;
    transition: 250ms all;
    transform: translateX(100%);
  }
  &.modal--open {
    visibility: visible;
    box-shadow: ${styles.BOX_SHADOW_DARK};
  }
  &.modal--open .modal__content {
    transform: translateX(0%);
  }
  &.modal--front .modal__content {
    width: calc(100vw - 0.5rem);
    left: 0.5rem;
    border-radius: 0;
  }
  &.modal--front .cross {
    left: initial;
    right: 2rem;
    top: 2rem;
    z-index: 32;
  }
  .modal__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    border-top: ${styles.BORDER};
    background: ${colors.WHITE()};
  }
`;

const ModalWrapper: React.FC = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<JSX.Element | undefined>();

  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback((m: IModalContent) => {
    if (m.content) {
      setContent(<m.content {...m.props} />);
      setIsOpen(true);
    }
  }, []);

  return (
    <ModalContext.Provider
      value={{
        open,
        close,
      }}
    >
      {props.children}
      <Styled
        className={`modal modal--${isOpen ? 'open' : 'closed'} modal--${
          environment.front ? 'front' : 'extension'
        }`}
      >
        {isOpen && <icons.Cross className="cross" onClick={close} />}
        <components.Mask active={isOpen} onClick={close} />
        <div className="modal__content">{isOpen && content}</div>
      </Styled>
    </ModalContext.Provider>
  );
};

export default ModalWrapper;
