import { useContext } from 'react';
import { WorkspaceBasicsFeaturesEnum } from 'herald-fe-shared';

import { WorkspaceContext } from 'components/context/WorkspaceWrapper';

export const useIsExposed = () => {
  const { active } = useContext(WorkspaceContext);
  return (
    active?.features?.includes(WorkspaceBasicsFeaturesEnum.Exposed) || false
  );
};

export const useSuggestTopics = () => {
  const { active } = useContext(WorkspaceContext);
  return (
    active?.features?.includes(WorkspaceBasicsFeaturesEnum.TopicSuggestion) ||
    false
  );
};
