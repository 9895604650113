import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  colors,
  utils,
  components,
  icons,
  ISearchIssue,
} from 'herald-fe-shared';

import { IssueTracker } from 'pages/ConnectIssue';
import { hooks } from 'lib';

interface IssueDescriptionProps {
  issue: null | ISearchIssue;
  tracker: null | IssueTracker;
}

const Styled = styled.div`
  .copy-button {
    padding: 1rem;
    border-radius: 5px;
    background: ${colors.PURPLE_LIGHT(0.1)};
    color: ${colors.PURPLE_LIGHT()};
    width: fit-content;
    cursor: pointer;
    transition: 250ms all;
    &:hover {
      background: ${colors.PURPLE_LIGHT(0.2)};
    }
  }
  .copy-button svg {
    fill: ${colors.PURPLE_LIGHT()};
    margin-right: 1rem;
  }
`;

const AddHeraldLink: React.FC<IssueDescriptionProps> = (props) => {
  const { issue, tracker } = props;
  const { data: workspace } = hooks.useWorkspace();

  const issueDescriptionText = useMemo(() => {
    if (!issue) {
      return null;
    }
    return (
      <p className="text-no-margin">
        As of now, your team has collected{' '}
        <strong>
          {issue.snippetCount} customer quote
          {(issue.snippetCount || 0) > 1 ? 's' : ''}
        </strong>{' '}
        for this topic in Herald:{' '}
        <a
          href={`${utils.strings.getAppOrigin()}/w/${workspace?.slug}/issues/${
            issue.id
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {issue.description}
        </a>
        .
      </p>
    );
  }, [issue, workspace]);

  const issueClipboardText = useMemo(() => {
    if (!issue) {
      return null;
    }
    const url = `${utils.strings.getAppOrigin()}/w/${workspace?.slug}/issues/${
      issue.id
    }`;
    const link = tracker?.markdown
      ? `**[${issue.description}](${url})**`
      : `${issue.description}: ${url}`;
    return `As of now, your team has collected ${
      issue.snippetCount
    } customer quote${
      (issue.snippetCount || 0) > 1 ? 's' : ''
    } for this topic in Herald: ${link}.`;
  }, [issue, workspace, tracker]);

  if (!issue || !issueClipboardText) {
    return null;
  }

  return (
    <Styled>
      <components.CopyButton
        text={issueClipboardText}
        successText="Copied comment to clipboard."
      >
        <components.ProTip style={{ marginTop: '1rem', cursor: 'pointer' }}>
          {issueDescriptionText}
        </components.ProTip>
        <components.Button secondary={true} large={true}>
          <icons.Copy />
          <span style={{ fontWeight: 'normal', marginLeft: 10 }}>
            Copy to Clipboard
          </span>
        </components.Button>
      </components.CopyButton>
    </Styled>
  );
};

export default AddHeraldLink;
