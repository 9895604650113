import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';

import styled from 'styled-components';
import {
  colors,
  styles,
  utils,
  components,
  icons,
  ISearchIssue,
} from 'herald-fe-shared';

import Nav from 'components/Nav';
import EmptyState from 'components/EmptyState';
import Warning from 'illustrations/Warning';
import IssueDescription from 'components/connect/IssueDescription';

import { LoadingContext } from 'components/context/LoadingWrapper';
import { SnippetContext } from 'components/context/SnippetWrapper';

import { hooks, api } from 'lib';

const { IssueSearch } = components.forms;

const Styled = styled.div`
  .content {
    padding: 3rem 2rem;
  }
  .content .container {
    position: initial;
    padding-top: 0;
  }
  .form-group--issue {
    border-top: none;
    margin-top: 2rem;
  }
  .form-group--issue .form-group__label {
    display: none;
  }
  .form-group--issue .form-group__content {
    margin-right: 0;
  }
  .integration-empty {
    margin-top: 10rem;
    padding: 1rem;
    background: ${colors.ORANGE(0.1)};
  }
  .sample-issue {
    margin: 2rem -2rem;
    padding: 1rem 2rem;
    background: ${colors.GRAY_2(0.3)};
    border-top: ${styles.BORDER};
    border-bottom: ${styles.BORDER};
    display: flex;
    align-items: center;
  }
  .sample-issue__count {
    background: ${colors.GRAY_2()};
    padding: 0.4rem;
    border-radius: 5px;
    overflow: hidden;
    flex: 0 0 2rem;
    height: 2rem;
    margin-right: 1rem;
    text-align: center;
  }
  .sample-issue__text h5,
  .sample-issue__count h5 {
    color: ${colors.GRAY_4()};
    font-size: 1rem;
    margin: 0;
  }
  .sample-issue__text {
    max-width: calc(100% - 8rem);
    flex: 1 1 auto;
    margin-right: 1rem;
  }
  .sample-issue__text h5 {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .sample-issue__link {
    padding: 0.5rem;
    height: 2rem;

    border-radius: 1rem;
    background: ${colors.PURPLE_LIGHT(0.15)};
    border: 1px solid ${colors.PURPLE_LIGHT(0.3)};
    opacity: 0.7;
    transition: 250ms all;
    cursor: pointer;
    width: fit-content;

    &:hover {
      opacity: 1;
    }

    svg {
      fill: ${colors.PURPLE_LIGHT()};
      height: 0.7rem;
    }
  }
  .sample-issue__link a {
    display: flex;
    align-items: center;
  }
  .linked__close {
    position: fixed;
    top: 6rem;
    right: 3rem;
    fill: ${colors.GRAY_3()};
    cursor: pointer;
    opacity: 0.6;
    transition: 250ms all;
  }
  .linked__close:hover {
    opacity: 1;
  }
  .footer {
    position: fixed;
    z-index: 33;
    bottom: 0;
    left: 0;
    padding: 1rem 2rem;
    width: 100%;
    background: ${colors.WHITE()};
    border-top: ${styles.BORDER};
    box-shadow: ${styles.BOX_SHADOW};
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.WHITE()};
    z-index: 100;
    transition: 250ms opacity;
    opacity: 0;
    visibility: hidden;
  }
  .mask--open {
    opacity: 0.95;
    visibility: visible;
  }
  .mask__message {
    height: 100%;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .mask__message__message {
    margin: 0 auto;
    margin-top: 2rem;
  }
  .mask__message__text {
    max-width: 20rem;
    margin-top: 2rem;
  }
  .mask__cross {
    position: absolute;
    right: 2rem;
    top: 2rem;
    fill: ${colors.PURPLE_LIGHT()};
    opacity: 0.6;
    cursor: pointer;
    transition: 250ms all;
  }
  .mask__message svg:hover {
    opacity: 1;
  }
`;

interface IssueTrackerMetadata {
  hostname: RegExp;
  issuePathMatcher: (path: string) => null | IssueTracker;
}

export interface IssueTracker {
  id: string;
  displayName: string;
  issueName: null | string;
  markdown?: boolean;
}

const ISSUE_TRACKERS_METADATA: IssueTrackerMetadata[] = [
  {
    hostname: new RegExp(/linear.app/),
    issuePathMatcher: (path: string) => {
      const matcher = new RegExp(/\/issue\/(.*)\/(.*)/);
      const match = matcher.exec(path);
      return {
        id: 'linear',
        displayName: 'Linear',
        issueName: match ? `issue ${match[1]}` : null,
        markdown: true,
      };
    },
  },
  {
    hostname: new RegExp(/github.com/),
    issuePathMatcher: (path: string) => {
      const matcher = new RegExp(/\/(.*)\/issues\/(.*)/);
      const match = matcher.exec(path);
      return {
        id: 'github',
        displayName: 'GitHub',
        issueName: match ? `[${match[1]}]-${match[2]}` : null,
        markdown: true,
      };
    },
  },
  {
    hostname: new RegExp(/gitlab.com/),
    issuePathMatcher: (path: string) => {
      const matcher = new RegExp(/\/(.*)\/-\/issues\/(.*)/);
      const match = matcher.exec(path);
      return {
        id: 'gitlab',
        displayName: 'GitLab',
        issueName: match ? `[${match[1]}]-${match[2]}` : null,
        markdown: true,
      };
    },
  },
  {
    hostname: new RegExp(/(.*).atlassian.net/),
    issuePathMatcher: (path: string) => {
      const matcher = new RegExp(/\/browse\/(.*)/);
      const match = matcher.exec(path);
      return {
        id: 'jira',
        displayName: 'JIRA',
        issueName: match ? `issue ${match[1]}` : null,
      };
    },
  },
  {
    hostname: new RegExp(/app.clubhouse.io/),
    issuePathMatcher: (path: string) => {
      const matcher = new RegExp(/\/(.*)\/story\/(.*)\/(.*)/);
      const match = matcher.exec(path);
      return {
        id: 'clubhouse',
        displayName: 'Clubhouse',
        issueName: match ? `story [${match[1]}]-${match[2]}` : null,
        markdown: true,
      };
    },
  },
];

interface IssueTrackerMatch {
  match: boolean;
  tracker: IssueTracker | null;
}

const getIssueTracker = (url: string) => {
  const urlObject = new URL(url);
  const tracker = ISSUE_TRACKERS_METADATA.find((i) =>
    i.hostname.exec(urlObject.hostname) ? true : false
  );
  if (tracker) {
    return {
      match: true,
      tracker: tracker.issuePathMatcher(urlObject.pathname),
    };
  }
  return { match: false, tracker: null };
};

export const useIssueTracker = (url: string): IssueTrackerMatch =>
  useMemo(() => {
    if (!url) {
      return { match: false, tracker: null };
    }
    return getIssueTracker(url);
  }, [url]);

const useLinkText = (tracker: IssueTracker | null, url: string) =>
  useMemo(() => {
    if (!url) {
      return <>this page</>;
    }
    if (!tracker) {
      const urlObject = new URL(url);
      return <>{urlObject.hostname}</>;
    }
    return (
      <>
        <strong>
          {tracker.displayName} {tracker.issueName}
        </strong>
      </>
    );
  }, [tracker, url]);

const AddHeraldLink: React.FC = () => {
  const { active: loading, set: setLoading } = useContext(LoadingContext);
  const { data: workspace } = hooks.useWorkspace();
  const { originalUrl: url } = useContext(SnippetContext);
  const { tracker } = useIssueTracker(url);

  const [issue, setIssue] = useState<ISearchIssue | null>(null);
  const [linkedIssue, setLinkedIssue] = useState<ISearchIssue | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [maskOn, setMaskOn] = useState(false);
  const [ready, setReady] = useState(false);

  const { data: originalIssue } = hooks.useIssue(issue?.id || '');

  const linkText = useLinkText(tracker, url);
  const issueSearchRef = useRef();

  // Reset validation error if user changes selected issue.
  useEffect(() => {
    if (error && url !== originalIssue?.summary.externalLink) {
      setError(null);
    }
  }, [issue, error, originalIssue, url]);

  useEffect(() => {
    if (api.ready && api.search?.issues) {
      api.search.issues
        .search<IAlgoliaTopic>(url, {
          hitsPerPage: 1,
        })
        .then((r) => {
          if (r.hits[0] && r.hits[0].externalLink === url) {
            setLinkedIssue({
              ...r.hits[0],
              isNew: false,
              id: r.hits[0].objectID,
            });
          }
          setReady(true);
        });
    }
  }, [issue, error, originalIssue, url]);

  useEffect(() => {
    if (
      issueSearchRef &&
      issueSearchRef.current &&
      (issueSearchRef.current as any).focus
    ) {
      (issueSearchRef as any).current.focus();
    }
  }, [issueSearchRef]);

  const addLink = useCallback(async () => {
    if (!issue) {
      setError('You must pick a Herald topic to associate this page to.');
      return;
    }
    if (originalIssue.summary.externalLink === url) {
      setError('This Herald topic is already linked to this page.');
      return;
    }
    setLoading(true);
    await api.update.issue({
      id: issue.id || '',
      externalLink: url,
    });
    setLoading(false);
    setMaskOn(true);
  }, [issue, url, originalIssue, setLoading]);

  if (!url) {
    return (
      <Styled className="container">
        <Nav />
        <div className="content">
          <EmptyState
            title={
              <h3 style={{ marginBottom: '1rem' }}>Adding Link Unsupported</h3>
            }
            description="Sorry, the extension can only be used to create link with Herald topics when it's opened on a supported page."
            icon={<Warning />}
          />
        </div>
      </Styled>
    );
  }

  if (!ready) {
    return (
      <Styled className="container">
        <Nav />
      </Styled>
    );
  }

  if (linkedIssue) {
    return (
      <Styled className="container">
        <Nav />
        <icons.Cross
          className="linked__close"
          onClick={() => setLinkedIssue(null)}
        />
        <div className="content">
          <p style={{ margin: '3rem 0' }}>
            This <strong>{tracker?.displayName}</strong> issue is already linked
            from a Herald topic. If you haven't already, you can copy the text
            below as a comment on {tracker?.displayName} so that your teammates
            have access to up-to-date customer feedback in Herald.
          </p>
          <IssueDescription issue={linkedIssue} tracker={tracker} />
        </div>
      </Styled>
    );
  }

  return (
    <Styled className="container">
      <Nav />
      <div className="content">
        {tracker ? (
          <p className="large">
            You can connect a Herald topic to {linkText} by searching for the
            topic below and clicking "Connect." It will add a link to{' '}
            {tracker?.displayName} to the Herald topic (example below).
          </p>
        ) : (
          <p className="large">
            You can connect a Herald topic to this URL on{' '}
            <strong>{linkText}</strong> by searching for the topic below and
            clicking "Connect." This is typically done to connect a Herald topic
            to an issue tracker like JIRA. It will add a link to the Herald
            topic (example below).
          </p>
        )}
        <div className="sample-issue">
          <div className="sample-issue__count">
            <h5>{issue?.snippetCount || '12'}</h5>
          </div>
          <div className="sample-issue__text">
            <h5>{issue?.description || 'Sample topic in the Herald webapp'}</h5>
          </div>
          <div className="sample-issue__link">
            <a href={url} target="_blank" rel="noopener noreferrer">
              <components.SourceLogo
                source={tracker?.displayName || ''}
                style={{ marginRight: 5 }}
              />
              <icons.ExternalLink />
            </a>
          </div>
        </div>

        <IssueSearch
          api={api}
          issues={issue ? [issue] : []}
          setIssues={(issues: any[] | null) => {
            if (issues && issues[0]) {
              setIssue(issues[0]);
            }
          }}
          workspace={workspace as any}
          error={error}
          disableCreate={true}
          issueRef={issueSearchRef}
        />

        {issue?.externalLink && (
          <components.ProTip
            style={{ background: colors.ORANGE(0.1), color: 'black' }}
          >
            This topic is already connected to{' '}
            {getIssueTracker(issue.externalLink)?.tracker ? (
              <strong>
                <a
                  href={issue.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getIssueTracker(issue.externalLink)?.tracker?.displayName}
                </a>
              </strong>
            ) : (
              <a
                href={issue.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {issue.externalLink}
              </a>
            )}
            . Pressing "connect" will remove that link and connect it to{' '}
            <strong>{tracker?.issueName}</strong>.
          </components.ProTip>
        )}

        {tracker?.id === 'linear' && !workspace?.integrationsEnabled?.linear && (
          <div className="integration-empty">
            <p className="text-no-margin">
              <strong>
                It looks like you haven't enabled Herald's Linear integration
                yet! You can still connect this issue to a Herald topic, but in
                order to see a link to customer quotes directly in Linear,{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${utils.strings.getAppOrigin()}/w/${
                    workspace?.slug
                  }/settings/integrations`}
                >
                  please enable our integration
                </a>
                .
              </strong>
            </p>
          </div>
        )}
      </div>

      <div className={`mask mask--${maskOn ? 'open' : 'closed'}`}>
        {maskOn && (
          <div className="mask__message">
            <icons.Cross
              onClick={() => window.close()}
              className="mask__cross"
            />
            <p className="large" style={{ marginTop: 50 }}>
              Success! Now you can copy the text below as a comment on{' '}
              {tracker?.displayName} so that your teammates have access to
              up-to-date customer feedback in Herald.
            </p>
            <IssueDescription issue={issue} tracker={tracker} />
            <components.Button
              onClick={() => window.close()}
              style={{ margin: 'auto' }}
            >
              Close
            </components.Button>
          </div>
        )}
        <div className="mask__message">
          <icons.Cross onClick={() => window.close()} />
          <h4 className="mask__message__message text-red">{error}</h4>
        </div>
      </div>

      <div className="footer">
        <components.ButtonGroup right={true}>
          <components.Button
            loading={loading}
            onClick={addLink}
            disabled={!issue}
          >
            Connect
          </components.Button>
        </components.ButtonGroup>
      </div>
    </Styled>
  );
};

export default AddHeraldLink;
