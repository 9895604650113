import React, { useState, useEffect } from 'react';
import Front from '@frontapp/plugin-sdk';

import { IParsedContact } from 'herald-fe-shared';
import domParsing from 'domParsing';
import { environment } from 'lib';

declare const chrome: any;

interface IParsedContactContext {
  contact?: IParsedContact;
  ready: boolean;
}

export const ParsedContactContext = React.createContext<IParsedContactContext>({
  ready: false,
});

const getParsedContact = (
  setContact: (c: IParsedContact | undefined) => void,
  setReady: (r: boolean) => void
) => {
  if (environment.extension && chrome.runtime && chrome.tabs) {
    chrome.tabs.executeScript(
      { code: `(${domParsing.toString()})()` },
      (res: SnippetData[]) => {
        const parsed = res ? res[0] : null;
        setContact(parsed?.contact || undefined);
        setReady(true);
      }
    );
  } else {
    setReady(true);
  }
};

const ParsedContactWrapper: React.FC = (props) => {
  const [ready, setReady] = useState(false);
  const [contact, setContact] = useState<undefined | IParsedContact>();

  useEffect(() => {
    getParsedContact(setContact, setReady);
    // Initi Front listeners
    Front.contextUpdates.subscribe((context) => {
      const { conversation: c } = context as any;
      switch (context.type) {
        case 'singleConversation':
          setContact({
            name: c?.recipient?.name,
            email: c?.recipient?.handle,
            searchId: c?.recipient?.handle,
          });
          setReady(true);
          return;
        default:
          return;
      }
    });
  }, []);

  return (
    <ParsedContactContext.Provider value={{ contact, ready }}>
      {props.children}
    </ParsedContactContext.Provider>
  );
};

export default ParsedContactWrapper;
