import React, { useMemo, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { components, icons } from 'herald-fe-shared';
import {
  Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { createHashHistory } from 'history';

import * as Mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';

import { AuthContext } from 'components/context/AuthWrapper';
import { SnippetContext } from 'components/context/SnippetWrapper';

import Login from 'pages/Login';
import AddQuote from 'pages/AddQuote';
import ConnectIssue, { useIssueTracker } from 'pages/ConnectIssue';
import NoWorkspace from 'pages/NoWorkspace';

import { api, hooks, environment } from 'lib';

declare const window: any;

const history = createHashHistory();

interface IRoute {
  pathname: string;
  component: React.FC;
  displayName: string;
  showInNav: boolean;
  icon?: React.FC;
  shortcut?: string[];
}

export const ROUTES: IRoute[] = [
  {
    pathname: '/login',
    component: Login,
    displayName: 'Login',
    showInNav: false,
  },
  {
    pathname: '/add-quote',
    component: AddQuote,
    displayName: 'Add Quote',
    showInNav: true,
    icon: icons.Feather,
    shortcut: ['alt', '1'],
  },
  {
    pathname: '/connect-issue',
    component: ConnectIssue,
    displayName: 'Connect Topic',
    showInNav: true,
    icon: icons.Random,
    shortcut: ['alt', '2'],
  },
  {
    pathname: '/no-workspace',
    component: NoWorkspace,
    displayName: 'No Workspace',
    showInNav: false,
  },
];
const APP_LOGIN_ROUTE = ROUTES[0];

const Styled = styled.div`
  position: relative;
  overflow-y: auto;
  width: 500px;
  height: 100vh;

  &.front {
    width: 100vw;
    height: 100vh;
  }

  .container {
    padding-top: 4rem;
    position: absolute;
    width: 100%;
  }

  div[tabindex='-1']:focus {
    outline: 0;
  }
`;

const FrontGlobalStyle = createGlobalStyle`
  body {
    width: 100%;
    height: 100%;
    max-width: none;
  }
`;

const useAppDefaultRoute = (): IRoute => {
  const { originalUrl: url } = useContext(SnippetContext);
  const { match } = useIssueTracker(url);
  return useMemo(() => {
    if (!url) {
      return ROUTES[1];
    }
    return match ? ROUTES[2] : ROUTES[1];
  }, [url, match]);
};

const useRedirect = (): JSX.Element | null => {
  const isLoggedIn = useContext(AuthContext)?.status;
  const isRoot = useRouteMatch('/')?.isExact || false;
  const isLogin = useRouteMatch(ROUTES[0].pathname)?.isExact || false;
  const isAddQuote = useRouteMatch(ROUTES[1].pathname)?.isExact || false;
  const isAddLink = useRouteMatch(ROUTES[2].pathname)?.isExact || false;
  const isNoWorkspace = useRouteMatch(ROUTES[3].pathname)?.isExact || false;

  const { ready } = useContext(SnippetContext);
  const { pathname: appDefaultPath } = useAppDefaultRoute();
  const { data: me } = hooks.useMe();

  return useMemo(() => {
    // If we haven't finished trying to parse the DOM yet, don't show anything.
    if (!ready) {
      return <div />;
    }

    // If the user is not logged in, make sure they get sent to login page.
    if (!isLoggedIn && !isLogin) {
      return <Redirect to={APP_LOGIN_ROUTE.pathname} />;
    }
    if (!isLoggedIn && isLogin) {
      return null;
    }

    // If the user is logged in and trying to access login page, redirect to app.
    if (isLoggedIn && isLogin) {
      return <Redirect to={appDefaultPath} />;
    }

    if (!api.activeWorkspace() && !me) {
      return <div />;
    }

    // If the user is at the root and logged in, redirect to app.
    if (isRoot && isLoggedIn) {
      return <Redirect to={appDefaultPath} />;
    }

    if (me && me.workspaces?.length === 0 && !isNoWorkspace) {
      return <Redirect to="/no-workspace" />;
    }

    if (me && me.workspaces && me.workspaces.length > 0 && isNoWorkspace) {
      return <Redirect to={appDefaultPath} />;
    }

    // If the user is at an unknown path and logged in, redirect to app.
    if (!isAddQuote && !isAddLink && isLoggedIn && !isNoWorkspace) {
      return <Redirect to={appDefaultPath} />;
    }

    // Otherwise, return null and render the contents of the page.
    return null;
  }, [
    isLoggedIn,
    isRoot,
    isLogin,
    isAddLink,
    isAddQuote,
    isNoWorkspace,
    me,
    ready,
    appDefaultPath,
  ]);
};

const AppRouter: React.FC = () => {
  const RedirectRule = useRedirect();

  useEffect(() => {
    const hash = window.location.hash.split('id_token=')[1];
    if (hash) {
      const idToken = hash.split('&')[0];
      window.localStorage.setItem('id_token', idToken);
    }
  }, []);

  if (RedirectRule) {
    return RedirectRule;
  }

  return (
    <Switch>
      {ROUTES.map((route) => (
        <Route
          key={route.pathname}
          path={route.pathname}
          exact
          component={route.component}
        />
      ))}
    </Switch>
  );
};

ROUTES.filter((r) => r.shortcut).forEach((r) => {
  Mousetrap.bindGlobal(r.shortcut?.join('+') || '', () => {
    if (history.location.pathname !== r.pathname) {
      history.push(r.pathname);
    }
  });
});

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Styled className={environment.front ? 'front' : 'extension'}>
        <components.GlobalStyle />
        {environment.front && <FrontGlobalStyle />}
        <AppRouter />
      </Styled>
    </Router>
  );
};

export default App;
