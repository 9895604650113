import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Front from '@frontapp/plugin-sdk';

import domParsing from 'domParsing';
import {
  ISearchContact,
  SearchIssue,
  SnippetVisibleEnum,
  utils,
} from 'herald-fe-shared';
import { auth, environment } from 'lib';

declare const chrome: any;

interface ILocalSnippet {
  url: string;
  text: string;
  note: string;
  priority: number;
  visibility: SnippetVisibleEnum;
  contact: null | ISearchContact;
  issues: SearchIssue[];
}

interface ILocalState {
  [url: string]: ILocalSnippet;
}

export interface IError {
  quote: string | null;
  contact: string | null;
  issue: string | null;
}

// originalUrl is the URL from which the extension is opened. This should be
// set always. Url might be blank if user opens extension without selecting text,
// this is just so we know not to carry the Url when/if a quote is created.
export interface SnippetContext extends ILocalSnippet {
  originalUrl: string;
  ready: boolean;
  error: IError;
  issueInputValue: string;
  setOriginalUrl: (x: string) => void;
  setUrl: (x: string) => void;
  setText: (x: string) => void;
  setNote: (x: string) => void;
  setContact: (x: null | ISearchContact) => void;
  setIssues: (x: SearchIssue[]) => void;
  setPriority: (x: number) => void;
  setVisibility: (x: SnippetVisibleEnum) => void;
  setReady: (x: boolean) => void;
  setError: (x: IError) => void;
  setIssueInputValue: (s: string) => void;
}

export const SnippetContext = React.createContext<SnippetContext>({} as any);

let defaultState: ILocalSnippet = {
  url: '',
  text: '',
  note: '',
  priority: 0,
  visibility: SnippetVisibleEnum.Visible,
  contact: null,
  issues: [null],
};

const defaultError: IError = {
  quote: null,
  contact: null,
  issue: null,
};

type ParsedSnippet = ILocalSnippet & { originalUrl?: string };

const OVERRIDE_URL_SOURCE = ['GDocs'];

const getParsed = async (): Promise<ParsedSnippet> =>
  new Promise((resolve) => {
    if (environment.extension && chrome.tabs) {
      chrome.tabs.executeScript(
        { code: `(${domParsing.toString()})()` },
        (res: SnippetData[]) => {
          const data = res ? res[0] : null;
          const state: ParsedSnippet = { ...defaultState };
          if (data) {
            const { text, url } = data;
            if (text) {
              state.text = text;
            }
            if (
              url &&
              (text ||
                OVERRIDE_URL_SOURCE.includes(
                  utils.strings.getSource(url) || 'null'
                ))
            ) {
              state.url = url;
            }
            if (url) {
              state.originalUrl = url;
            }
          }
          resolve(state);
        }
      );
    } else {
      resolve();
    }
  });

const SnippetWrapper: React.FC = (props) => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [url, setUrl] = useState(defaultState.url);
  const [contact, setContact] = useState(defaultState.contact);
  const [text, setText] = useState(defaultState.text);
  const [note, setNote] = useState(defaultState.note);
  const [priority, setPriority] = useState(defaultState.priority);
  const [visibility, setVisibility] = useState(defaultState.visibility);
  const [issues, setIssues] = useState(defaultState.issues);
  const [error, setError] = useState<IError>(defaultError);
  const [ready, setReady] = useState(false);
  const [issueInputValue, setIssueInputValue] = useState('');

  const cachedValues = useMemo(
    () => ({ url, text, note, contact, priority, visibility, issues }),
    [url, text, note, contact, priority, visibility, issues]
  );

  const setState = useCallback((def: ParsedSnippet, overwrite?: boolean) => {
    if (def.originalUrl) {
      setOriginalUrl(def.originalUrl);
    }
    if (def.url) {
      setUrl(def.url);
    }
    if (def.contact) {
      setContact(def.contact);
    }
    if (def.issues || overwrite) {
      setIssues(def.issues);
    }
    if (def.text || overwrite) {
      setText(def.text);
    }
    if (def.note || overwrite) {
      setNote(def.note);
    }
    if (def.priority || overwrite) {
      setPriority(def.priority);
    }
    if (def.visibility || overwrite) {
      setVisibility(def.visibility);
    }
  }, []);

  // Called whenever the URL changes. Usually, this only happens once per load except for Front plugin.
  const getDefaultState = useCallback(async () => {
    const cache = auth.store.get.state<ILocalState>();
    // When in Front plugin, parsing function will return undefined, but originalUrl should be updated as per the useEffect hook listening to Front context updates below.
    const parsedState = (await getParsed()) || { originalUrl };
    const cachedState =
      cache && parsedState?.originalUrl
        ? cache[parsedState?.originalUrl]
        : null;
    const newState = {
      ...defaultState,
      ...parsedState,
      ...cachedState,
      url: cachedState?.url || parsedState?.url || defaultState.url,
      text: cachedState?.text || parsedState?.text || defaultState.text,
    };
    setState(newState, true);
    setReady(true);
  }, [setState, originalUrl]);

  useEffect(() => {
    getDefaultState();
  }, [getDefaultState]);

  useEffect(() => {
    const cachedState = auth.store.get.state<ILocalState>();
    if (originalUrl) {
      auth.store.set.state<ILocalState>({
        ...cachedState,
        [originalUrl]: cachedValues,
      } as any);
    }
  }, [cachedValues, originalUrl]);

  // Front parsing code. Called once per session, but attaches a subscription to Front that updates the originalUrl whenever the conversation in context changes.
  useEffect(() => {
    Front.contextUpdates.subscribe((context) => {
      const { conversation: c } = context as any;
      // Clear issue search when URL changes (mainly for switching emails in Front).
      setIssueInputValue('');
      switch (context.type) {
        case 'singleConversation':
          const url = `https://app.frontapp.com/open/${c.id}`;
          // For front, both URL and originalUrl are the same because we always want to carry the URL when quotes are submitted.
          setOriginalUrl(url);
          setUrl(url);
          return;
        default:
          return;
      }
    });
  }, []);

  return (
    <SnippetContext.Provider
      value={{
        ...cachedValues,
        originalUrl,
        error,
        ready,
        setOriginalUrl,
        setContact,
        setText,
        setUrl,
        setIssues,
        setNote,
        setPriority,
        setVisibility,
        setReady,
        setError,
        issueInputValue,
        setIssueInputValue,
      }}
    >
      {props.children}
    </SnippetContext.Provider>
  );
};

export default SnippetWrapper;
