import React from 'react';
import ReactDOM from 'react-dom';

import Context from 'components/context/ContextWrapper';
import App from './App';

// Manually insert the font CSS for now.
const head = document.getElementsByTagName('head')[0];
const link = document.createElement('link');
link.rel = 'stylesheet';
link.type = 'text/css';
link.href = 'https://use.typekit.net/wvk0xro.css';
link.media = 'all';
head.appendChild(link);

ReactDOM.render(
  <Context>
    <App />
  </Context>,
  document.getElementById('root')
);
