import { API, Auth, Logger, Hooks } from 'herald-fe-shared';
import { LoadingContext } from 'components/context/LoadingWrapper';
import { detect } from 'detect-browser';
import { utils } from 'herald-fe-shared';

import isElectron from 'is-electron';
import * as Mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';

declare const chrome: any;

// Figure out environment this is being loaded in.

const electron = isElectron();
const browser = detect();

export const environment = {
  electron,
  front: window.location.ancestorOrigins
    ? Array.from(window.location.ancestorOrigins).some((url) =>
        url.startsWith('https://app.frontapp.com')
      )
    : window.location.search.startsWith('?auth_secret='),
  extension:
    window.location.origin.startsWith('chrome-extension://') ||
    window.location.origin.startsWith('moz-extension://'),
  chrome: browser?.name === 'chrome',
  firefox: browser?.name === 'firefox',
  mac: browser?.os === 'Mac OS',
  windows: browser?.os?.includes('Windows'),
  linux: browser?.os?.includes('Linux'),
};

const getClientId = () => {
  const defaultId = process.env.REACT_APP_CLIENT;
  if (environment.firefox && environment.extension && defaultId) {
    return `firefox_addon/production ${defaultId.split(' ').pop()}`;
  }
  return defaultId || '';
};

const path = process.env.REACT_APP_API_BASE_URL || '';
const algoliaId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID || '';
const clientId = getClientId();
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};

export const auth = new Auth(firebaseConfig);
export const api = new API(auth, algoliaId, path, clientId, true);
export const logger = new Logger({ hideNotifications: true });
export const hooks = new Hooks(api, logger, LoadingContext);

let port: any = null;

try {
  if (chrome && chrome.runtime) {
    port = chrome.runtime.connect({ name: 'HERALD_MESSAGING' });
  }
} catch {}

export const sendMessage = (event: string) => {
  if (port) {
    port.postMessage({ event });
  }
};

const SHORTCUTS = {
  SAVE: 'mod+enter',
  SAVE_MODAL: 'mod+enter',
};

const SHORTCUT_HANDLERS: { [key: string]: boolean } = {};

export const setShortcut = (key: string, s: () => void) => {
  if (SHORTCUTS[key]) {
    if (key === 'SAVE' && SHORTCUT_HANDLERS.SAVE_MODAL) {
      return;
    }
    SHORTCUT_HANDLERS[key] = true;
    Mousetrap.bindGlobal(SHORTCUTS[key], s);
  }
};

export const removeShortcut = (key: string) => {
  if (SHORTCUTS[key]) {
    SHORTCUT_HANDLERS[key] = false;
  }
};

export const getAppUniqueId = (url: string) => {
  const source = utils.strings.getSource(url);
  switch (source) {
    case 'Intercom':
      return url.split('/')[url.split('/').length - 1];
    case 'Gmail':
      return url.split('/')[url.split('/').length - 1];
    default:
      return url;
  }
};
