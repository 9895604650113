import React from 'react';
import styled from 'styled-components';
import { components, colors, icons } from 'herald-fe-shared';
import { auth, environment } from 'lib';

declare const window: any;
declare const browser: any;

export const TOKEN_ID = 'id_token';

const Styled = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 600px;
  padding: 2rem;
  padding-bottom: 8rem;
  background: ${colors.GRAY_1()};
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .logo {
    animation: 2s fadeup;
    margin-bottom: 1rem;
  }
  button h5 {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
  }
  button svg {
    margin-left: 0.4rem;
    margin-top: 4px;
    width: 3.5rem;
    height: 2rem;
  }
  .signup-logo path {
    fill: ${colors.PURPLE_LIGHT()};
  }
  button svg path {
    fill: ${colors.WHITE()};
  }
`;

// For Firefox, always check on load if there is a token in localstoage.
const token = window.localStorage.getItem(TOKEN_ID);
if (token) {
  localStorage.removeItem(TOKEN_ID);
  const credential = auth.getCredential.google(token);
  auth.signin.firebase(credential);
}

const firefoxLogin = () => {
  const redirectURL = browser.identity.getRedirectURL();
  const scopes = ['openid', 'email', 'profile'];
  const authURL = `https://accounts.google.com/o/oauth2/auth?client_id=${
    process.env.REACT_APP_GOOGLE_CLIENT_ID
  }&prompt=consent&response_type=id_token&redirect_uri=${encodeURIComponent(
    redirectURL
  )}&scope=${encodeURIComponent(scopes.join(' '))}`;
  browser.runtime.sendMessage({
    type: 'INIT_LOGIN',
    data: {
      url: authURL,
      interactive: true,
    },
  });
};

const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_PUBLIC_URL}&response_type=id_token&scope=email profile&prompt=consent&nonce=herald`;
let windowRef: any = null;

const electronLogin = (url: string, name: string) => {
  if (windowRef === null || windowRef.closed) {
    window.localStorage.removeItem(TOKEN_ID);
    const win = (windowRef = window.open(
      url,
      name,
      'width=600, height=700, top=100, left=100'
    ));
    let interval: any;
    const checkForToken = () => {
      if (win.closed) {
        clearInterval(interval);
        return;
      }
      const token = window.localStorage.getItem(TOKEN_ID);
      if (token) {
        clearInterval(interval);
        window.localStorage.removeItem(TOKEN_ID);
        win.close();
        const credential = auth.getCredential.google(token);
        auth.signin.firebase(credential);
      }
    };
    interval = setInterval(checkForToken, 300);
  } else {
    windowRef.focus();
  }
};

const login = () => {
  if (environment.electron) {
    electronLogin(url, 'Google Sign In');
    return;
  } else if (environment.firefox && browser) {
    firefoxLogin();
    return;
  }
  auth.signin.google();
};

const Login: React.FC = () => {
  return (
    <Styled>
      <div className="logo">
        <components.Logo width={200} />
      </div>
      <components.ButtonGroup
        style={{ justifyContent: 'center', marginTop: '3rem' }}
      >
        {!environment.front && (
          <components.Button
            secondary={true}
            large={true}
            onClick={login}
            style={{ marginRight: '1rem' }}
          >
            Sign Up with <icons.GoogleLogo className="signup-logo" />
          </components.Button>
        )}

        <components.Button large={true} onClick={login}>
          Sign In with <icons.GoogleLogo />
        </components.Button>
      </components.ButtonGroup>
    </Styled>
  );
};

export default Login;
