import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { components, styles, IssueSummaryVisibleEnum } from 'herald-fe-shared';

import { SnippetContext } from 'components/context/SnippetWrapper';
import { ModalContext, ModalFooter } from 'components/context/ModalWrapper';

import { hooks, environment, setShortcut, removeShortcut } from 'lib';
import { useIsExposed } from 'lib/hooks';

const { IssueDescription, IssueLabels, IssueVisibility } = components.forms;

const StyledModal = styled.div`
  .header {
    padding: 2rem;
    border-bottom: ${styles.BORDER};
  }
  .content {
    padding: 0 2rem;
  }
  .form-group {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  .input__container {
    height: 3rem;
  }
  input {
    font-size: 1.2rem;
  }
`;

const IssueEditModal: React.FC<{ id: number }> = (props) => {
  const { id } = props;
  const { issues, setIssues } = useContext(SnippetContext);
  const { data: labels } = hooks.useLabels();
  const { close } = useContext(ModalContext);

  const issue = issues && issues[id];
  const [description, setDescription] = useState(issue?.description || '');
  const [selectedLabels, setSelectedLabels] = useState(issue?.labels || []);
  const [visible, setVisible] = useState(
    issue?.visible || IssueSummaryVisibleEnum.Visible
  );

  const nameRef = useRef(null);
  const isExposed = useIsExposed();

  const labelOptions = useMemo(
    () =>
      labels
        ? labels.map((label) => ({
            value: label.id,
            label: <components.LabelPill label={label} />,
            data: label,
          }))
        : [],
    [labels]
  );

  const onSave = useCallback(() => {
    const newIssues = issues ? [...issues] : [];
    newIssues[id] = {
      isNew: true,
      description,
      labels: selectedLabels.map((l) => ({ ...l, label: l.data })) as any,
      visible,
    };
    setIssues(newIssues);
    close();
  }, [close, description, selectedLabels, visible, setIssues, id, issues]);

  useEffect(() => {
    if (nameRef && nameRef.current) {
      setTimeout(() => (nameRef.current as any).focus(), 250);
    }
  }, [nameRef]);

  useEffect(() => {
    setShortcut('SAVE_MODAL', onSave);
    return () => removeShortcut('SAVE_MODAL');
  }, [onSave]);

  return (
    <StyledModal>
      <div className="header">
        <h4>Edit Issue</h4>
      </div>
      <IssueDescription
        description={description}
        setDescription={setDescription}
        horizontal={!environment.front}
        inputProps={{ ref: nameRef }}
      />
      <IssueLabels
        options={labelOptions}
        labels={(selectedLabels || []).map((l) => ({
          ...l,
          label: <components.LabelPill label={l.data} />,
        }))}
        setLabels={setSelectedLabels}
        horizontal={!environment.front}
      />
      {isExposed && (
        <IssueVisibility
          visible={visible}
          setVisible={setVisible}
          horizontal={!environment.front}
        />
      )}
      <ModalFooter>
        <components.ButtonGroup right={true}>
          <components.Button secondary={true} onClick={close}>
            Cancel
          </components.Button>
          <components.Tooltip
            message={
              <>
                <code>{environment.mac ? '⌘' : 'Ctrl'}</code>
                <code>Enter</code>
              </>
            }
          >
            <components.Button onClick={onSave}>OK</components.Button>
          </components.Tooltip>
        </components.ButtonGroup>
      </ModalFooter>
    </StyledModal>
  );
};

export default IssueEditModal;
